<template src="./financialInformation.html"></template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { companyStateRoutine, companyGetters, COMPANY_CLEANUP, companySelectRoutine } from '@/store/modules/company/routines'
import { fundingGetters } from '@/store/routines'
import { getFinancialData, setFinancialData } from '@/api/financialData'
import { completeFundingApplication } from '@/api/fundingApplication'
import { prefillFormData } from '@/utils/formHelpers'
import DynamicForm from '@/components/forms/DynamicForm'
import JourneyHeader from '../journeyHeader'
import financialForm from './financialInformation.form'
import deepcopy from 'deepcopy'
import { toastDefaultOptions } from '../../../config/vue-toast'
import { sendBasicEventToAmplitude, journeyStepsButtonAmplitudeEvent } from '@/utils/utils'
import { amplitudeEvents } from '@/utils/constants'
import CurrencySymbol from '@/components/CurrencySymbol'
import steps from '@/features/journey/steps'

export default {
  name: 'FundingRequirements',
  components: {
    JourneyHeader,
    CurrencySymbol,
    DynamicForm
  },
  mixins: [steps],
  data() {
    return {
      financialInformationForm: {},
      submitPromise: null,
      amplitudeEvents,
      sendBasicEventToAmplitude,
      journeyStepsButtonAmplitudeEvent
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      fundingApplicationId: fundingGetters.FUNDING_APP_ID
    })
  },
  methods: {
    ...mapActions({
      requestCompanyState: companyStateRoutine.TRIGGER,
      cleanupCompany: COMPANY_CLEANUP,
      handleOnSelectCompany: companySelectRoutine.TRIGGER
    }),
    async preloadFinancilaData(form) {
      try {
        const { data } = await getFinancialData(this.companyId)
        await prefillFormData(form, data)
      } catch (e) {
        console.log(`Can't preload ${form.formTitle} Form`)
        this.$toasted.show('Something went wrong. We are unable to preload the financial information.', { ...toastDefaultOptions, type: 'error', icon: 'error' })
      }
    },
    async submitFinancialData(form) {
      this.$ma.trackEvent({
        eventType: 'Your Financial Information Form data',
        eventProperties: {
          'Do you have credit / debit card terminals?': form.data.isCreditDebitCardTerminals,
          'Do you receive purchase orders from your customers?': form.data.isPurchaseOrders,
          'Have you been approved for an R&D Tax credit refund, but yet to receive payment?': form.data.isRAndDTaxRefund,
          'What is your average monthly revenue from the terminals?': form.data.creditDebitCardMonthlyAverage,
          'Whats your average monthly purchase orders?': form.data.purchaseOrdersMonthlyAverage
        }
      })

      sendBasicEventToAmplitude(this.$ma, journeyStepsButtonAmplitudeEvent('FINANCIAL_INFO_NEXT'))

      await setFinancialData({
        ...form.data,
        companyId: this.companyId
      })
      await completeFundingApplication({ fundingApplicationId: this.fundingApplicationId })
      await this.requestCompanyState(this.companyId)
      this.$router.push({ name: this.nextStepName })
    },
    async selectCompany(companyId) {
      await this.handleOnSelectCompany(companyId)
      this.$router.push('/')
    },
    async init() {
      await this.preloadFinancilaData(this.financialInformationForm)
    }
  },
  created() {
    this.financialInformationForm = deepcopy(financialForm())
    this.init()
  }
}
</script>
