import config from '@/config/global'
import dictionary from '@/config/dictionary'

export default {
  name: 'financialDataForm',
  routeName: 'financial-information',
  formTitle: 'Financial Information',
  navTitle: 'Financial Information',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      fields: [
        [
          {
            field: 'input',
            type: 'checkbox',
            name: 'isEstablishedLessOneYear',
            isCountable: false,
            selected: false
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'yearToDateTurnover',
            veeAs: 'year to date turnover',
            label: `What is the year to date ${dictionary.turnover} for the business?`,
            placeholder: '',
            rules: 'required|decimal',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'netProfitLastYear',
            veeAs: ' ',
            label: 'What was the operating profit in the last financial year?',
            placeholder: '',
            rules: 'required|decimal',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            dependsOn: [
              {
                fieldName: 'isEstablishedLessOneYear',
                values: [false]
              }
            ],
            selected: ''
          },
          {
            field: 'dummy',
            dependsOn: [
              {
                fieldName: 'isEstablishedLessOneYear',
                values: [false]
              }
            ]
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'yearToDateOperating',
            veeAs: ' ',
            label: 'What is the year to date operating profit for the business?',
            placeholder: '',
            rules: 'required|decimal',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'estimatedTurnover',
            veeAs: 'estimated turnover',
            label: `What is your estimated ${dictionary.turnover} this year?`,
            placeholder: '',
            rules: 'required|decimal',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            dependsOn: [
              {
                fieldName: 'isEstablishedLessOneYear',
                values: [true]
              }
            ],
            selected: ''
          },
          {
            field: 'dummy',
            dependsOn: [
              {
                fieldName: 'isEstablishedLessOneYear',
                values: [true]
              }
            ]
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'estimatedGrossProfit',
            veeAs: 'estimated gross profit',
            label: 'What was your estimated Gross Profit this year?',
            placeholder: '',
            rules: 'required|decimal',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            dependsOn: [
              {
                fieldName: 'isEstablishedLessOneYear',
                values: [true]
              }
            ],
            selected: ''
          },
          {
            field: 'dummy',
            dependsOn: [
              {
                fieldName: 'isEstablishedLessOneYear',
                values: [true]
              }
            ]
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'estimatedNetProfit',
            veeAs: 'estimated net profit',
            label: 'What was your estimated Net Profit this year?',
            placeholder: '',
            rules: 'required|decimal',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            dependsOn: [
              {
                fieldName: 'isEstablishedLessOneYear',
                values: [true]
              }
            ],
            selected: ''
          },
          {
            field: 'dummy',
            dependsOn: [
              {
                fieldName: 'isEstablishedLessOneYear',
                values: [true]
              }
            ]
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'employeesCount',
            veeAs: 'employees',
            label: 'How many employees work in the business?',
            placeholder: '',
            rules: 'required|numeric',
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'isCreditDebitCardTerminals',
            veeAs: ' ',
            label: 'Do you have credit / debit card terminals?',
            placeholder: '',
            rules: 'required',
            options: [
              {
                label: 'Yes',
                value: true
              },
              {
                label: 'No',
                value: false
              }
            ],
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'creditDebitCardMonthlyAverage',
            veeAs: 'credit debit card monthly average',
            label: 'What is the average monthly revenue from the terminals?',
            placeholder: '',
            rules: 'required',
            options: [
              {
                label: `Less than ${config.whitelabel.country.currency.defaultCurrencySymbol}2k`,
                value: 0
              },
              {
                label: `${config.whitelabel.country.currency.defaultCurrencySymbol}2k - ${config.whitelabel.country.currency.defaultCurrencySymbol}5k`,
                value: 2000
              },
              {
                label: `${config.whitelabel.country.currency.defaultCurrencySymbol}5k - ${config.whitelabel.country.currency.defaultCurrencySymbol}10k`,
                value: 5000
              },
              {
                label: `${config.whitelabel.country.currency.defaultCurrencySymbol}10k - ${config.whitelabel.country.currency.defaultCurrencySymbol}50k`,
                value: 10000
              },
              {
                label: `${config.whitelabel.country.currency.defaultCurrencySymbol}100k +`,
                value: 100000
              }
            ],
            dependsOn: [
              {
                fieldName: 'isCreditDebitCardTerminals',
                values: [true]
              }
            ],
            selected: ''
          },
          {
            field: 'dummy',
            dependsOn: [
              {
                fieldName: 'isCreditDebitCardTerminals',
                values: [true]
              }
            ]
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'isPurchaseOrders',
            veeAs: ' ',
            label: 'Do you receive purchase orders from your customers?',
            placeholder: '',
            rules: 'required',
            options: [
              {
                label: 'Yes',
                value: true
              },
              {
                label: 'No',
                value: false
              }
            ],
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'purchaseOrdersMonthlyAverage',
            veeAs: 'purchase orders monthly average',
            label: "What's the average monthly purchase orders?",
            placeholder: '',
            rules: 'required',
            options: [
              {
                label: `Less than ${config.whitelabel.country.currency.defaultCurrencySymbol}2k`,
                value: 0
              },
              {
                label: `${config.whitelabel.country.currency.defaultCurrencySymbol}2k - ${config.whitelabel.country.currency.defaultCurrencySymbol}5k`,
                value: 2000
              },
              {
                label: `${config.whitelabel.country.currency.defaultCurrencySymbol}5k - ${config.whitelabel.country.currency.defaultCurrencySymbol}10k`,
                value: 5000
              },
              {
                label: `${config.whitelabel.country.currency.defaultCurrencySymbol}10k - ${config.whitelabel.country.currency.defaultCurrencySymbol}50k`,
                value: 10000
              },
              {
                label: `${config.whitelabel.country.currency.defaultCurrencySymbol}100k +`,
                value: 100000
              }
            ],
            dependsOn: [
              {
                fieldName: 'isPurchaseOrders',
                values: [true]
              }
            ],
            selected: ''
          },
          {
            field: 'dummy',
            dependsOn: [
              {
                fieldName: 'isPurchaseOrders',
                values: [true]
              }
            ]
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'isRAndDTaxRefund',
            veeAs: ' ',
            label: `Have you been approved for an ${dictionary.taxCreditLabel} Tax credit refund, but yet to receive payment?`,
            placeholder: '',
            rules: 'required',
            options: [
              {
                label: 'Yes',
                value: true
              },
              {
                label: 'No',
                value: false
              }
            ],
            selected: ''
          },
          {
            field: 'dummy'
          }
        ]
      ]
    }
  ]
}
